import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql, PageProps } from 'gatsby';
import { Wrapper } from 'components/common/wrapper/Wrapper';
import Section from 'components/common/section/Section';
import PageTitle from 'components/directus/page-title/PageTitle';
import AdditionServiceAds from 'components/directus/additional-service-ads/AdditionalServiceAds';
import { getFieldBySlug } from 'utils/directus';
import { DirectusPageData } from 'interfaces/directus-page-data.interface';
import FaqInfoPanel from 'components/directus/faq-info-panel/FaqInfoPanel';
import ServiceContent from 'components/directus/service-content/ServiceContent';
import CustomerReviews from 'components/directus/customer-reviews/CustomerReviews';

export default ({ data }: PageProps<DirectusPageData>): JSX.Element => {
  const slug = 'bizik-answering';
  const { node } = getFieldBySlug(slug, data.allDirectusPage.edges);

  return (
    <>
      <Helmet>
        <title>{node.title}</title>
        <meta name="title" content={node.title} />
        <meta name="description" content={node.description} />
        <meta name="robots" content={node.robots} />
        <link rel="canonical" href={node.canonical} />
      </Helmet>
      <Wrapper>
        <Section bottom={0}>
          <PageTitle slug={slug} buyService />
        </Section>
        <Section bottom={30}>
          <ServiceContent columns={1} slug={slug} />
        </Section>
        <Section>
          <FaqInfoPanel slug={slug} />
        </Section>
        <Section bottom={50}>
          <AdditionServiceAds slug={slug} bizik />
        </Section>
        <Section>
          <CustomerReviews />
        </Section>
      </Wrapper>
      {/* <ProductRichSnippet slug={slug} /> */}
    </>
  );
};

export const query = graphql`
  query {
    allDirectusPage(filter: { slug: { eq: "bizik-answering" } }) {
      edges {
        node {
          title
          slug
          description
          robots
          canonical
        }
      }
    }
  }
`;
